
.error-icon 	{ color:#990000; }
.highlight 		{ color:#009900; }
.success-icon 	{ color:#009900; }

.alert {
	padding:6px;
	.glyphicon {
		top:2px;
	}
	ul {
		margin:0;
		padding:0 0 0 5px;
		list-style:none;
	}
}

#delForm {
	display:inline-block;
	button {
		padding:0;
		border:none;
		background-color:transparent;
		span {
			color:#337ab7;
		}
	}
}

#stores_modal {
	.modal-dialog {
		.modal-body {
			ul {
				padding:0;
				margin:0;
				list-style:none;
				li {
					h4 {
						margin:0;
					}
				}
			}
		}
	}
}

.input-group-lg > select.form-control {
	padding:10px 0;
}

.container.theme-showcase {
	margin-top:20px;
}

blockquote {
	font-size:14px;
	h4 {
		margin:0 0 10px;
		font-size:16px;
		font-weight:bold;
	}
	ul {
		margin:0 0 20px;
		padding:0;
		list-style:none;
	}
}

.navbar {
    border-radius: 0;
    margin-bottom: 0;
}

@media (max-width:768px){
	#navbar .navbar-nav {
		margin:0 -15px;
	}
	#navbar .navbar-nav li {
		border-bottom:1px solid #000;
	}
	#navbar .navbar-nav li a {
		color:#FFF;
		padding:15px;
		font-size:18px;
	}
}

.navbar-brand {
	padding:5px 15px;
}

#footer {
	padding:140px 0 20px;
	text-align: center;
}


#activity {
	ul {
		padding:0 0 0 15px;
		li {
			color:#999;
			a {
				color:#333;
				&:hover {
					color:#337ab7;
				}
			}
		}
	}
}


/* Call */
#call {
	.pg {
		color:#999;
		width:100%;
		text-align:right;
	}
}
/* End Call */

.no-route {

}


/* Call Stores */
#call-stores {
	ul {
		margin:0;
		padding:0;
		list-style:none;
		li {
			.store_title {
				font-size:18px;
			}
			.store_location {
				font-size:14px;
			}
			button {
				&:hover {
					color:#337ab7;
				}
			}
		}
	}
}
/* End Call Stores */


/* Call List */
#call-list {
	margin:0;
	padding:0;
	list-style:none;
	border-top:1px solid #d7d7d7;
	.call-item {
		cursor:pointer;
		padding:7px 10px 0;
		font-size:16px;
		line-height:18px;
		border-left:5px solid #d7d7d7;
		border-bottom:1px solid #d7d7d7;
		span {
			color:#666666;
		}
		&.odd {
			background-color:#f9f9f9;
		}
		&.active {
			border-left:5px solid #337ab7;
		}
	}
}
/* End Call */

/* Notes View Location */
.page-notes-view {
	#notes {
		margin:0;
		padding:0 0 0 20px;
		display:inline-block;
		li {
			padding-bottom:15px;
			.desc {
				margin:10px 0;
			}
		}
	}
}
/* End Notes View Location */