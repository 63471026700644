#map {
	height:400px;
	border:1px solid #AAA;
}

#map-calls {
	width:300px;
}

.map-content {
	h3 {
		margin:0 0 5px;
	}
	.map-address {
		color:#333;
		font-size:16px;
	}
	.map-date {
	}
}