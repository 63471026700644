@import "bootstrap/bootstrap";

@import "global.less";

@import "googlemaps.less";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}

#cameraButtonContainer
{
	position: relative;
	overflow: hidden;
	direction: ltr;
	display: none;
}

#cameraButtonContainer .ios
{
	display: block;
}

#cameraButton
{
	position: absolute;
	right: 0;
	top: 0;
	font-family: Arial, serif;
	font-size: 118px;
	margin: 0;
	padding: 0;
	cursor: pointer;
	opacity: 0;
}
